<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="clips"
          :show-select="canDeleteMedia"
          :headers="headers"
          :provider="archivesProvider"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn
              v-if="canDeleteMedia"
              dark
              color="error"
              small
              @click="deleteAll"
            >
              Delete
            </v-btn>
          </template>
          <template #item.title="{ item }">
            <EReadMore :content="item.title" :max-length="50" />
          </template>
          <template #item.cameraName="{ item }">
            {{ item.cameraName }}
            <ImpersonateUserButton
              v-if="canImpersonate"
              :email="item.requesterEmail"
              :target-route="`/projects/${item.projectExid}/${item.cameraExid}`"
            />
          </template>
          <template #item.requesterName="{ item }">
            {{ item.requesterName }}
            <ImpersonateUserButton
              v-if="canImpersonate"
              :email="item.requesterEmail"
            />
          </template>
          <template #item.status="{ item }">
            {{ getStatus(item.status, item.type) }}
          </template>
          <template #item.type="{ item }">
            {{ getType(item.type) }}
          </template>
          <template #item.fromDate="{ item }">
            {{ formatDate(item.fromDate) }}
          </template>
          <template #item.toDate="{ item }">
            {{ formatDate(item.toDate) }}
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template #item.completedAt="{ item }">
            {{ getDuration(item) }}
          </template>
          <template #item.embedCode="{ item }">
            <CopyToClipboardBtn
              v-if="item.embedCode"
              :text="item.embedCode"
              icon
              color="primary"
            />
          </template>
          <template #item.download="{ item }">
            <span
              v-if="
                item.status === CompareStatus.Completed &&
                item.type === MediaType.Compare
              "
            >
              <a
                v-for="(extension, index) in ['gif', 'mp4']"
                :key="index"
                :href="`${$config.public.apiURL}/cameras/${item.cameraExid}/compares/${item.exid}.${extension}`"
                :download="`${$config.public.apiURL}/cameras/${item.cameraExid}/compares/${item.exid}.${extension}`"
              >
                <v-icon small color="primary"> fa-cloud-download-alt </v-icon>
                {{ extension.toUpperCase() }}
              </a>
            </span>
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import headers from "@/components/mediaHub/mediaHubHeaders"
import filterFields from "@/components/mediaHub/mediaHubSearchFilters"
import { MediaTypes, MediaStatuses } from "@/components/constants.js"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import ReportTable from "@/components/ReportTable"
import { MediaType } from "@evercam/shared/types/media"
import { CompareStatus } from "@evercam/shared/types/compare"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useReportStore } from "@/stores/report"
import { mapStores } from "pinia"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.MEDIA_HUB.VIEW],
  },
  components: {
    ImpersonateUserButton,
    ReportTable,
    CopyToClipboardBtn,
  },
  data: () => {
    return {
      headers,
      filterFields,
    }
  },
  head() {
    return {
      title: "Admin - Clips",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    archivesProvider() {
      return AdminApi.mediaHub.getAllMedia
    },
    canDeleteMedia() {
      return this.$permissions.user.can(PERMISSIONS.MEDIA_HUB.DELETE_MEDIA)
    },
    canImpersonate() {
      return this.$permissions.user.can(PERMISSIONS.USERS.IMPERSONATE)
    },
    filteredStatuses() {
      const selectedType = this.reportStore.searchFilters?.type

      const hasCustomStatuses = [
        MediaType.Compare,
        MediaType.Timelapse,
      ].includes(selectedType)

      if (!selectedType || !hasCustomStatuses) {
        return MediaStatuses
      }

      return MediaStatuses.filter((status) => selectedType in status.ids)
    },
  },
  watch: {
    filteredStatuses: {
      handler(statuses) {
        this.setStatusFilterItems("status", statuses)
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.CompareStatus = CompareStatus
    this.MediaType = MediaType
  },
  methods: {
    getStatus(status, archiveType) {
      const hasCustomId = [MediaType.Compare, MediaType.Timelapse].includes(
        archiveType
      )

      return MediaStatuses.find((el) => {
        const id = hasCustomId ? el.ids[archiveType] : el.ids.default

        return typeof id === "number" ? id === parseInt(status) : id === status
      })?.name
    },
    getType(type) {
      return MediaTypes.find((el) => el.value === type)?.name
    },
    setStatusFilterItems(field, items) {
      this.filterFields[field].attributes = {
        items: [{ value: "", name: "All" }, ...items],
        value: "",
      }
    },
    getDuration(item) {
      const end = this.$moment.utc(item.completedAt),
        start = this.$moment.utc(item.createdAt),
        duration = end.diff(start, "seconds")

      return isNaN(duration) ? "-" : `${duration} seconds`
    },
    async deleteAll() {
      if (this.reportStore.selectedItems.length === 0) {
        this.$notifications.warn("At least select one media!")

        return
      }
      const timelapseIds = this.getMediaIds(this.reportStore.selectedItems, [
        MediaType.Timelapse,
      ])
      const compareIds = this.getMediaIds(this.reportStore.selectedItems, [
        MediaType.Compare,
      ])
      const archiveIds = this.getMediaIds(this.reportStore.selectedItems, [
        MediaType.ExternalUrl,
        MediaType.EditedImage,
        MediaType.File,
        MediaType.Clip,
        MediaType.LocalClip,
        MediaType.XRay,
        MediaType.MobileAsset,
      ])

      if (
        await this.$confirmDialog.open({
          title: "Delete media(s) ?",
          message: "This will permanently delete selected media(s)!",
        })
      ) {
        try {
          await AdminApi.mediaHub.deleteMedia({
            timelapseIds,
            compareIds,
            archiveIds,
          })
          for (const media of this.reportStore.selectedItems) {
            this.reportStore.items = this.reportStore.items.filter(
              (item) => item.id !== media.id
            )
          }
          this.reportStore.selectedItems = []
          this.$notifications.success("Media(s) deleted successfully.")
        } catch (error) {
          this.$notifications.error({
            text: "Failed to delete media(s)!",
            error,
          })
        }
      }
    },
    getMediaIds(items, types) {
      return items
        .filter((item) => types.includes(item.type))
        .map((el) => el.id)
    },
  },
}
</script>
