import { MediaTypes } from "@/components/constants.js"

export default {
  cameraName: {
    component: "TextFieldSearchFilter",
  },
  requesterName: {
    component: "TextFieldSearchFilter",
  },
  title: {
    component: "TextFieldSearchFilter",
  },
  type: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...MediaTypes],
      value: "",
    },
  },
  status: {
    component: "SelectSearchFilter",
    attributes: {
      items: [],
      value: "",
    },
  },
}
